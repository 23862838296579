import React, { useState, useEffect, Component } from "react"
import Layout from "../components/layout"
import config from "../utils/siteConfig"
import Button from "../components/Atoms/Button/Button"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faDatabase, faPlay, faIndustry, faBuilding, faBookOpen, faCode, faCube } from "@fortawesome/free-solid-svg-icons"

export default function Home({ data }) {
  debugger;
  const page = data.allContentfulZHomeNew.edges[0].node

  const Bold = ({ children }) => <span className="bold3">{children}</span>
  //const Text = ({ children }) => <p className="align-center">{children}</p>;

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="heading1">{children}</h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="heading2">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h4 className="bulletPoint">{children}</h4>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="bulletPoint">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h4 className="h5-medium-rtxt text-darkgray2">{children}</h4>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img src={`https:${node.data.target.fields.file["en-US"].url}`} />
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="d-inline-block">{children}</p>
      ),

    },
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderText: (text) =>
      text
        .replace(/\u2028/g, "")
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br />, text])
  }

  //for responsiveness
  const [width, setWindowWidth] = useState(0)
  useEffect(() => {

    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () =>
      window.removeEventListener('resize', updateDimensions);
  }, [])
  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const responsive = {
    webContainer: width > 960
  }

  const {
    id,
    metaData,
    hnHRbtn,
    hnHHd,
    hnHHd2,
    hnHRtxt,
    hnHImage,
    hnHBtn,
    hnHBtn2,
    hnS1Hd,
    hnS1Rtxt,
    hnS1Image,
    hnS2Hd,
    hnS2Rtxt,
    hnS6Hd,
    hnS6Btn,
    hnS1Btn,
    hnS1Hd2,
    hnS1Image2,
    hnS2Btn,
    hnS2Hd2,
    hnS2Image,
    hnS2aBtn,
    hnS2aHd2,
    hnS2aHd,
    hnS2aImage,
    hnS2aRtxt,
    hnS2bHd,
    hnS2bHd2,
    hnS2bRtxt,
    hnS2bRtxt2,
    hnS2bRtxt3,
    hnS2bRtxt4,
    hnS2bImage,
    hnS2bBtn,
    hnS3Hd,
    hnS3Rtxt,
    hnS3Rtxt2,
    hnS3Rtxt3,
    hnS3Rtxt4,
    hnFHd,
    hnFTxt,
    hnFBtn,
  } = page

  return (
    <Layout
      IsHeaderImageDark={false}
      navColour={"white"}>
      <head>
        <title>{metaData.title}</title>
        <link rel="alternate" hreflang="de" href="https://wunderloop.io/de" />
        <link rel="alternate" hreflang="en" href="https://wunderloop.io/en" />
        <link rel="shortcut icon" href="https://wunderloop.io/favicon.ico" />
        <meta charset="utf-8" />
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords.keywords} />
        <meta name="google-site-verification" content="dSIGFo4IWwK_6FyDSLhlkeRwuCMV9o5iSI1JpmS82xc" />
      </head>

      {/*Header section*/}
      <section className="bg-white">
        <div className="head-container">
          <div className="head-canvas">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div class="row pt-2">
                  <div class="text-center align-items-center">
                    {/*  <Button
                      className='btns'
                      buttonStyle='btn--outlineGray'
                      buttonSize='btn--small'
                      href={config.navigationURL_register}
                    >
                      {documentToReactComponents(hnHRbtn.json, options)}
                    </Button>*/}
                  </div>
                </div>
                <div className="row pt-5">
                  <h1 className="h1-large h1-darkBlue">
                    {hnHHd}
                  </h1>
                </div>
                <div className="row pt-4">
                  <h1 className="h1-largeLight2 h1-darkBlue">
                    {hnHHd2.hnHHd2}
                  </h1>
                </div>
                <div className="row pt-4">
                  <h2 className="h2-large text-darkgray">
                    {documentToReactComponents(hnHRtxt.json, options)}
                  </h2>
                </div>
                <div class="row pt-4">
                  <div class="text-center">
                    <Button
                      className='btns'
                      buttonStyle='btn--outline'
                      buttonSize='btn--large'
                      href={config.navigationURL_register}
                    >
                      {hnHBtn}{" "}
                      <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                    </Button>
                  </div>
                  <div class="text-center">
                    <Button
                      className='btns'
                      buttonStyle='btn--primaryBlack'
                      buttonSize='btn--large'
                      href={config.navigationURL_contactSales}
                    >
                      {hnHBtn2}{" "}
                      <FontAwesomeIcon className="" size="1x" icon={faChevronRight} className="faChevronRight" />
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 bg-white">
                <div className="text-center pt-3">
                  <img style={{ width: "60%" }} src={hnHImage.file.url} className="img-fluid pt-5" alt={hnHImage.title} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*section 1*/}
      <section className="bg-lightgray">
        <div className="sectionFit-container">
          {responsive.webContainer === true ? (
            <div className="row align-items-center">
              <div className="col-lg-6 pr-5 pl-0 pb-5">
                <img src={hnS1Image.file.url} className="img-fluid" alt={hnS1Image.title} />
              </div>
              <div className="col-lg-6 pr-4 pb-5">
                <div className="row">
                  <h1 className="align-top h1-small h1-purple">
                    {hnS1Hd2}
                  </h1>
                </div>
                <div className="row pt-4">
                  <h1 className="align-top h1-medium h1-darkBlue">
                    {hnS1Hd}
                  </h1>
                </div>
                <div className="row mb-4 pt-2">
                  <h2 className="h2-large text-darkgray pt-4 pr-5">
                    {documentToReactComponents(hnS1Rtxt.json, options)}
                  </h2>
                  <div className="row pl-3 pt-4">
                    <div class="text-center">
                      <Button
                        className='btns'
                        buttonStyle='btn--outlinePurple'
                        buttonSize='btn--large'
                        href={config.navigationURL_product}
                      >
                        {hnS1Btn}{" "}
                        <FontAwesomeIcon className="pl-1 pt-1" icon={faChevronRight} className="faChevronRight" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>) : (
            <div className="row">

              <div className="col-lg-6 pr-4 pb-5">
                <div className="row">
                  <h1 className="align-top h1-small h1-purple">
                    {hnS1Hd2}
                  </h1>
                </div>
                <div className="row pt-4">
                  <h1 className="align-top h1-medium h1-darkBlue">
                    {hnS1Hd}
                  </h1>
                </div>
                <div className="row mb-4 pt-2">
                  <h2 className="h2-large text-darkgray pt-4 pr-5">
                    {documentToReactComponents(hnS1Rtxt.json, options)}
                  </h2>
                  <div className="row pl-3 pt-4">
                    <div class="text-center">
                      <Button
                        className='btns'
                        buttonStyle='btn--outlinePurple'
                        buttonSize='btn--large'
                        href={config.navigationURL_product}
                      >
                        {hnS1Btn}{" "}
                        <FontAwesomeIcon className="pl-1 pt-1" icon={faChevronRight} className="faChevronRight" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pr-5 pl-0 pb-5">
                <img src={hnS1Image.file.url} className="img-fluid" alt={hnS1Image.title} />
              </div>
            </div>
          )}
          {/*<div className="row pt-5">
            <div className="text-center pt-5">
              <img src={hnS1Image2.file.url} className="img-fluid" alt={hnS1Image2.title} />
            </div>
          </div>*/}
        </div>
      </section>

      {/*section 2*/}
      <section className="bg-darkblue">
        <div className="sectionFit-container">
          <div className="row">
            <div className="col-lg-6 pr-4 pb-5">
              <div className="row">
                <h1 className="align-top h1-small h1-blue">
                  {hnS2Hd2}
                </h1>
              </div>
              <div className="row pt-4">
                <h1 className="align-top h1-medium text-white">
                  {hnS2Hd}
                </h1>
              </div>
              <div className="row mb-4 pt-2">
                <h2 className="h2-large h2-lightgray pt-4 pr-5">
                  {documentToReactComponents(hnS2Rtxt.json, options)}
                </h2>
                <div className="row pl-3 pt-4">
                  <div class="text-center">
                    <Button
                      className='btns'
                      buttonStyle='btn--outlineBlue'
                      buttonSize='btn--large'
                      href={config.navigationURL_product}
                    >
                      {hnS2Btn}{" "}
                      <FontAwesomeIcon className="pl-1 pt-1" icon={faChevronRight} className="faChevronRight" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-4 pr-0 pb-5">
              <img src={hnS2Image.file.url} className="img-fluid" alt={hnS2Image.title} />
            </div>
          </div>
        </div>
      </section>

      {/*section 2a*/}
      <section className="bg-white">
        <div className="sectionFit-container">
          {responsive.webContainer === true ? (
            <div className="row">
              <div className="col-lg-6 pr-5 pb-5">
                <img src={hnS2aImage.file.url} className="img-fluid" alt={hnS2aImage.title} />
              </div>
              <div className="col-lg-6 pr-4 pb-5">
                <div className="row">
                  <h1 className="align-top h1-small h1-purple">
                    {hnS2aHd2}
                  </h1>
                </div>
                <div className="row pt-4">
                  <h1 className="align-top h1-medium h1-darkBlue">
                    {hnS2aHd}
                  </h1>
                </div>
                <div className="row mb-4 pt-2">
                  <h2 className="h2-large text-darkgray pt-4 pr-5">
                    {documentToReactComponents(hnS2aRtxt.json, options)}
                  </h2>
                  <div className="row pl-3 pt-4">
                    <div class="text-center">
                      <Button
                        className='btns'
                        buttonStyle='btn--outlinePurple'
                        buttonSize='btn--large'
                        href={config.navigationURL_sustainability}
                      >
                        {hnS2aBtn}{" "}
                        <FontAwesomeIcon className="pl-1 pt-1" icon={faChevronRight} className="faChevronRight" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          ) : (
            <div className="row">
              <div className="col-lg-6 pr-4 pb-5">
                <div className="row">
                  <h1 className="align-top h1-small h1-purple">
                    {hnS2aHd2}
                  </h1>
                </div>
                <div className="row pt-4">
                  <h1 className="align-top h1-medium h1-darkBlue">
                    {hnS2aHd}
                  </h1>
                </div>
                <div className="row mb-4 pt-2">
                  <h2 className="h2-large text-darkgray pt-4 pr-5">
                    {documentToReactComponents(hnS2aRtxt.json, options)}
                  </h2>
                  <div className="row pl-3 pt-4">
                    <div class="text-center">
                      <Button
                        className='btns'
                        buttonStyle='btn--outlinePurple'
                        buttonSize='btn--large'
                        href={config.navigationURL_sustainability}
                      >
                        {hnS2aBtn}{" "}
                        <FontAwesomeIcon className="pl-1 pt-1" icon={faChevronRight} className="faChevronRight" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pr-5 pl-0 pb-5">
                <img src={hnS2aImage.file.url} className="img-fluid" alt={hnS2aImage.title} />
              </div>
            </div>
          )}
        </div>
      </section>

      {/*section 2b*/}
      <section className="bg-lightgray">
        <div className="sectionFit-container mt-1 mb-4">
          <div className="row">
            <h1 className="align-top h1-small h1-purple">
              {hnS2bHd2}
            </h1>
          </div>
          <div className="row text-white pt-4">
            <h1 className="align-top h1-medium h1-darkBlue">
              {hnS2bHd}
            </h1>
          </div>
          {responsive.webContainer === true ? (
            <div className="row text-white pt-5">

              <div className="col-lg-3 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/icon_data_driven.svg")}
                  style={{ width: "25%" }}
                  alt="image"
                /><p className="p-regular4 text-darkgray pt-2">
                  {documentToReactComponents(hnS2bRtxt.json, options)}
                </p>
              </div>
              <div className="col-lg-3 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/icon__design_not_built.svg")}
                  style={{ width: "25%" }}
                  alt="image"
                /><p className="p-regular4 text-darkgray pt-2">
                  {documentToReactComponents(hnS2bRtxt2.json, options)}
                </p>
              </div>
              <div className="col-lg-3 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/Icon_Plug_and_play.svg")}
                  style={{ width: "25%" }}
                  alt="image"
                /><p className="p-regular4 text-darkgray pt-2">
                  {documentToReactComponents(hnS2bRtxt3.json, options)}
                </p>
              </div>
              <div className="col-lg-3 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/Icon_user_centric.svg")}
                  style={{ width: "25%" }}
                  alt="image"
                /><p className="p-regular4 text-darkgray pt-2">
                  {documentToReactComponents(hnS2bRtxt4.json, options)}
                </p>
              </div>
            </div>
          ) : (
            <div className="row text-white pt-5">

              <div className="col-lg-3 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/icon_data_driven.svg")}
                  style={{ width: "10%" }}
                  alt="image"
                /><p className="p-regular4 text-darkgray pt-2">
                  {documentToReactComponents(hnS2bRtxt.json, options)}
                </p>
              </div>
              <div className="col-lg-3 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/icon__design_not_built.svg")}
                  style={{ width: "10%" }}
                  alt="image"
                /><p className="p-regular4 text-darkgray pt-2">
                  {documentToReactComponents(hnS2bRtxt2.json, options)}
                </p>
              </div>
              <div className="col-lg-3 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/Icon_Plug_and_play.svg")}
                  style={{ width: "10%" }}
                  alt="image"
                /><p className="p-regular4 text-darkgray pt-2">
                  {documentToReactComponents(hnS2bRtxt3.json, options)}
                </p>
              </div>
              <div className="col-lg-3 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/Icon_user_centric.svg")}
                  style={{ width: "10%" }}
                  alt="image"
                /><p className="p-regular4 text-darkgray pt-2">
                  {documentToReactComponents(hnS2bRtxt4.json, options)}
                </p>
              </div>
            </div>
          )}
        </div>
      </section>

      {/*Section 3*/}
      <section className="bg-white">
        <div className="sectionFit-container bg-white">
          <div className="row pb-3">
            <h1 className="align-top h1-darkBlue h1-medium">
              {hnS3Hd}
            </h1>
          </div>
          {responsive.webContainer === true ? (
            <div className="row text-white pt-5">
              <div className="col-lg-4 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/product-occupancy/analytics-and-reports.svg")}
                  style={{ width: "15%" }}
                  alt="image"
                />
                <p className="p-regular4 text-darkgray pt-4">
                  {documentToReactComponents(hnS3Rtxt.json, options)}
                </p>
              </div>
              <div className="col-lg-4 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/product-occupancy/warnings.svg")}
                  style={{ width: "15%" }}
                  alt="image"
                />
                <p className="p-regular4 text-darkgray pt-4">
                  {documentToReactComponents(hnS3Rtxt2.json, options)}
                </p>
              </div>
              <div className="col-lg-4 pl-0 pr-5 pb-5">
                <img
                  src={require("../images/Icons/product-occupancy/warnings.svg")}
                  style={{ width: "15%" }}
                  alt="image"
                />
                <p className="p-regular4 text-darkgray pt-4">
                  {documentToReactComponents(hnS3Rtxt3.json, options)}
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="row align-items-center pt-5">
                <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                  <img
                    src={require("../images/Icons/product-occupancy/analytics-and-reports.svg")}
                    style={{ width: "100%" }}
                    alt="image"
                  />
                </div>
                <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                  <p className="p-regular2 text-darkgray3 pt-4">
                    {documentToReactComponents(hnS3Rtxt.json, options)}
                  </p>
                </div>
              </div>
              <div className="row align-items-center pt-2">
                <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                  <img
                    src={require("../images/Icons/product-occupancy/warnings.svg")}
                    style={{ width: "100%" }}
                    alt="image"
                  />
                </div>
                <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                  <p className="p-regular2 text-darkgray3 pt-4">
                    {documentToReactComponents(hnS3Rtxt2.json, options)}
                  </p>
                </div>
              </div>
              <div className="row align-items-center pt-2">
                <div className="col-md-1 col-sm-1 col-1 pl-0 pr-5 pb-5">
                  <img
                    src={require("../images/Icons/product-occupancy/warnings.svg")}
                    style={{ width: "100%" }}
                    alt="image"
                  />
                </div>
                <div className="col-md-10 col-sm-10 col-10 pl-5 pr-5 pb-5">
                  <p className="p-regular2 text-darkgray3 pt-4">
                    {documentToReactComponents(hnS3Rtxt3.json, options)}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      {/*Footer section*/}
      <section className="bg-darkblue">
        <div className="sectionFit-container">
          <div className="coloum">
            <div className="mb-4 px-4 pt-4">
              <div className="text-center">
                <h1 className="h1-mediumSmall text-white pt-4">
                  {hnFHd}
                </h1>
              </div>
              <div className="text-center">
                <h2 className="h2-large h2-lightgray pt-4">
                  {hnFTxt}
                </h2>
              </div>
              <div class="h-100 text-center pt-4">
                <Button
                  className='btns'
                  buttonStyle='btn--outlineWhite'
                  buttonSize='btn--large'
                  href={config.navigationURL_contactUs}
                >
                  {hnFBtn}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  );
}


export const query = graphql`
      query($locale: String!) {
        allContentfulZHomeNew(
          filter: {
        node_locale: {eq: $locale }
  }) {
        edges {
        node {
        id
        metaData {
        slug
          title
      description
      keywords {
        keywords
      }
        }
      hnHRbtn {
        json
      }
      hnHHd
      hnHHd2 {
        hnHHd2
      }
      hnHRtxt {
        json
      }
      hnHImage {
        title
          description
      file {
        url
            fileName
          }
        }
      hnHBtn
      hnHBtn2
      hnS1Hd
      hnS1Rtxt {
        json
      }
      hnS1Image {
        title
          description
      file {
        url
            fileName
          }
        }
      hnS1Btn
      hnS1Hd2
      hnS1Image2 {
        description
          file {
        fileName
            url
          }
      title
        }
      hnS2Btn
      hnS2Hd2
      hnS2Image {
        description
          title
      file {
        fileName
            url
          }
        }
      hnS2Hd
      hnS2Rtxt {
        json
      }
      hnS2aHd
      hnS2aHd2
      hnS2aRtxt {
        json
      }
      hnS2aBtn
      hnS2aImage {
        description
          title
      file {
        fileName
            url
          }
        }
      hnS2bHd
      hnS2bHd2
      hnS2bRtxt {
        json
      }
      hnS2bRtxt2 {
        json
      }
      hnS2bRtxt3 {
        json
      }
      hnS2bRtxt4 {
        json
      }
      hnS2bImage  {
        description
          title
      file {
        fileName
            url
          }
        }
      hnS2bBtn
      hnS3Hd
      hnS3Rtxt {
        json
      }
      hnS3Rtxt2 {
        json
      }
      hnS3Rtxt3 {
        json
      }
      hnFHd
      hnFTxt
      hnFBtn
      }
    }
  }
}
      `
